<template>
  <div class="row justify-center items-center loading-wrapper">
    <q-spinner-pie color="orange" size="5.5em"></q-spinner-pie>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.loading-wrapper {
    height: 101vh;
}
</style>