import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/components/HomePage";

const routes = [{
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "/test",
        name: "Test",
        component: () =>
            import ("@/components/TestPage.vue"),
    },
    // {
    //     path: "/demo",
    //     name: "Demo",
    //     component: () =>
    //         import ("@/components/DemoPage.vue"),
    // },
    {
        path: "/technologies",
        name: "Tech",
        component: () =>
            import ("@/components/TechPage.vue"),
    },
    // {
    //     path: "/smart-core",
    //     name: "SmartCore",
    //     component: () =>
    //         import ("@/components/Solutions/SmartCore.vue"),
    // },
    // {
    //     path: "/smart-camera",
    //     name: "SmartCamera",
    //     component: () =>
    //         import ("@/components/Solutions/SmartCamera.vue"),
    // },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () =>
            import ("@/components/NotFound.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        }
        return { top: 0, behavior: "smooth" };
    },
    routes,
});

export default router;