import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
// import iconSet from 'quasar/icon-set/fontawesome-v6'
import quasarUserOptions from './quasar-user-options'
import VueClickAway from "vue3-click-away";
import router from './router.js';
import MyDirective from './directives/scrollanimation';


const app = createApp(App)
app.use(VueClickAway)
app.use(Quasar, quasarUserOptions)
// app.use(iconSet, iconSet) // fas fa-brain
app.use(router) //plugin for vuejs
app.directive('scrollanimation', MyDirective.bind)
app.mount('#app');