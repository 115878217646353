<template>
  <div class="body">
    <q-layout view="lHh lpr lFf">
      <q-header
        id="header"
        class="q-py-xs"
        :class="(productsPage || isScrolled) ? 'active' : 'passive'"
      >
        <div class="row no-wrap">
          <q-btn
            flat
            round
            dense
            icon="menu"
            size="19px"
            :class="`${
              $q.screen.xl || $q.screen.lg || $q.screen.md ? 'hidden' : ''
            } q-ml-sm`"
            @click="clickedMenu"
          />
          <q-toolbar
            :class="`col-xs-12 col-sm-6 col-md-3 col-lg-2 q-pl-lg`"
            shrink
          >
            <q-avatar>
              <q-img
                src="@/assets/mainLogo.png"
                no-spinner
                :ratio="1"
                fit="fill"
              />
            </q-avatar>
            <q-toolbar-title class="toolbar-title"
              ><router-link to="/">iThermAI</router-link></q-toolbar-title
            >
          </q-toolbar>

          <q-toolbar
            :class="`${
              $q.screen.xl || $q.screen.lg || $q.screen.md
                ? 'col-md-6 col-lg-8'
                : 'hidden'
            } q-pl-lg justify-center`"
          >
            <div class="row wrap justify-center">
              <!-- <q-btn
                flat
                stretch
                class="q-px-md q-py-none col-auto toolbar-navs"
                @mouseover="showSolutions = true"
                label="Solutions"
                style="fontWeight: bold"
              >
                <q-menu
                  v-model="showSolutions"
                  @mouseleave="showSolutions = false"
                  fit
                >
                  <q-list class="dropdown-wrapper" separator>
                    <q-item class="dropdown-item" clickable>
                      <q-item-section
                        ><router-link to="/smart-core"
                          >Smart Core</router-link
                        ></q-item-section
                      >
                    </q-item>
                    <q-item class="dropdown-item" clickable>
                      <q-item-section
                        ><router-link to="/smart-camera"
                          >Smart Camera</router-link
                        ></q-item-section
                      >
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn> -->
              <q-btn
                flat
                stretch
                class="q-px-md q-py-none col-auto toolbar-navs"
              >
                <router-link to="/" @click="scrollTop">Home</router-link>
              </q-btn>
              <q-btn
                flat
                stretch
                class="q-px-md q-py-none col-auto toolbar-navs"
              >
                <router-link
                  :to="{ name: 'Home', hash: '#applications' }"
                  @click="changeScroll"
                  >Fire Shield</router-link
                >
              </q-btn>
              <q-btn
                flat
                stretch
                class="q-px-md q-py-none col-auto toolbar-navs"
              >
                <router-link
                  :to="{ name: 'Home', hash: '#about' }"
                  @click="changeScroll"
                  >About</router-link
                >
              </q-btn>
              <q-btn
                flat
                stretch
                class="q-px-md q-py-none col-auto toolbar-navs"
              >
                <router-link
                  :to="{ name: 'Home', hash: '#partner' }"
                  @click="changeScroll"
                  >Partner</router-link
                >
              </q-btn>
              <q-btn
                flat
                stretch
                class="q-px-md q-py-none col-auto toolbar-navs"
              >
                <router-link
                  :to="{ name: 'Home', hash: '#contact' }"
                  @click="changeScroll"
                  >Contact</router-link
                >
              </q-btn>
              <q-btn
                flat
                stretch
                class="q-px-md q-py-none col-auto toolbar-navs"
              >
                <router-link
                  :to="{ name: 'Home', hash: '#products' }"
                  @click="changeScroll"
                  >Others</router-link
                >
              </q-btn>
            </div>
          </q-toolbar>

          <q-toolbar class="col-xs-5 col-md-3 col-lg-2 q-pl-lg">
            <q-space />
            <q-btn
              no-caps
              class="q-mr-sm q-px-lg gradient-background start-btn"
              @click="getStarted"
              :to="{ name: 'Home', hash: '#about' }"
            >
              <div class="toolbar-btn">Get Started</div>
            </q-btn>
          </q-toolbar>
        </div>
      </q-header>

      <q-drawer v-model="leftDrawerOpen" :width="180" elevated>
        <div class="gradient-background q-drawer">
          <q-list>
            <q-item-label
              header
              class="q-my-sm text-white"
              style="font-size: 20px"
              >Essential Links</q-item-label
            >
            <q-separator />
            <!-- <q-expansion-item
              expand-separator
              label="Solutions"
              class="q-my-xs q-mt-sm"
            >
              <q-list>
                <q-item style="padding-left: 35px">
                  <q-item-section
                    ><router-link to="/smart-core"
                      >Smart Core</router-link
                    ></q-item-section
                  >
                </q-item>
                <q-item style="padding-left: 35px">
                  <q-item-section
                    ><router-link to="/smart-camera"
                      >Smart Camera</router-link
                    ></q-item-section
                  >
                </q-item>
              </q-list>
            </q-expansion-item> -->
            <q-item class="q-my-xs">
              <router-link to="/" @click="scrollTop">Home</router-link>
            </q-item>
            <q-item class="q-my-xs">
              <router-link
                :to="{ name: 'Home', hash: '#applications' }"
                @click="changeScroll"
                >Fire Shield</router-link
              >
            </q-item>
            <q-item class="q-my-xs">
              <router-link
                :to="{ name: 'Home', hash: '#about' }"
                @click="changeScroll"
                >About</router-link
              >
            </q-item>
            <q-item class="q-my-xs">
              <router-link
                :to="{ name: 'Home', hash: '#partner' }"
                @click="changeScroll"
                >Partners</router-link
              >
            </q-item>
            <q-item class="q-my-xs">
              <router-link
                :to="{ name: 'Home', hash: '#contact' }"
                @click="changeScroll"
                >Contact</router-link
              >
            </q-item>
            <q-item class="q-my-xs">
              <router-link
                :to="{ name: 'Home', hash: '#products' }"
                @click="changeScroll"
                >Others</router-link
              >
            </q-item>
          </q-list>
        </div>
      </q-drawer>

      <q-page-container style="padding-top: 0px">
        <TemplatePage />
        <FooterPage />
      </q-page-container>

      <div class="scroll-btn" v-if="needScrollbtn">
        <q-img
          src="./assets/flame-vertically.png"
          :ratio="9 / 16"
          no-spinner
          @click="scrollTop"
          style="cursor: pointer"
        />
      </div>
    </q-layout>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  onUnmounted,
  provide,
  defineAsyncComponent,
  watch,
} from "vue";
import { useRoute } from "vue-router";

import TemplatePage from "./components/TemplatePage";

const FooterPage = defineAsyncComponent({
  loader: () =>
    import(/* webpackPrefetch: true */ "./components/FooterPage.vue"),
});

export default {
  name: "LayoutDefault",

  components: {
    TemplatePage,
    FooterPage,
  },

  setup() {
    const leftDrawerOpen = ref(false);
    const slide = ref("picture");
    const isScrolled = ref(false);
    const route = useRoute();
    const needScrollbtn = ref(false);
    const showSolutions = ref(false);
    const productsPage = ref(false);
    provide("isScrolled", isScrolled);

    watch(route, () => {
      
      changeBackground();
      if (route.path == "/technologies") {
        productsPage.value = true;
      } else {
        productsPage.value = false;
      }
      console.log(productsPage.value, route.path, route.path == "/technologies");
      console.log(isScrolled.value, productsPage.value || isScrolled.value);
    });

    function getStarted() {
      isScrolled.value = true;
    }

    function changeBackground() {
      if (window.scrollY >= 50) {
        isScrolled.value = true;
        needScrollbtn.value = true;
      } else if (leftDrawerOpen.value == true && !productsPage.value) {
        isScrolled.value = true;
      } else {
        isScrolled.value = false;
        needScrollbtn.value = false;
      }
    }

    function scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    function changeScroll() {
      isScrolled.value = true;
    }

    function clickedMenu() {
      leftDrawerOpen.value = !leftDrawerOpen.value;
    }

    onMounted(() => {
      window.addEventListener("scroll", changeBackground);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", changeBackground);
    });

    return {
      leftDrawerOpen,
      changeBackground,
      slide,
      isScrolled,
      getStarted,
      scrollTop,
      changeScroll,
      clickedMenu,
      needScrollbtn,
      showSolutions,
      productsPage
    };
  },
};
</script>

<style>
@import url("./styles/animation.scss");
</style>

<style scoped lang="scss">
.active {
  background-color: black;
}
.passive {
  background-color: transparent;
}
#header a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.gradient-background {
  background-color: #a40606;
  background-image: linear-gradient(
    316deg,
    rgba(185, 37, 37, 0.6),
    rgba(236, 225, 63, 0.6)
  );
}
.q-drawer,
.width-height-full {
  width: 100%;
  height: 100%;
}
.q-drawer {
  a {
    text-decoration: none;
    color: white;
  }
  color: white;
  font-weight: bold;
}
.toolbar-title {
  font-size: 28px;
}
.toolbar-navs {
  font-size: 15px;
}
.toolbar-btn {
  font-size: 17px;
  margin: 2px;
}
.scroll-btn {
  position: fixed;
  bottom: 8px;
  right: 13px;
  width: 50px;
  z-index: 20;
}
.dropdown-wrapper {
  background-color: #a40606;
  background-image: linear-gradient(
    316deg,
    rgba(185, 37, 37, 0.6),
    rgba(236, 225, 63, 0.6)
  );
  min-width: 100px;
  text-transform: uppercase;
}
.dropdown-item {
  padding: 13px;
  a {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
}
@media (max-width: 450px) {
  .start-btn {
    display: none;
  }
  .scroll-btn {
    right: 8px;
    width: 30px;
  }
}
</style>
