<template>
  <div class="main-wrapper">
    <FullCarousel :gallery="gallery" :autoplay="true" :thumbnails="true"/>
    <div>
      <q-img
        src="@/assets/first-camera.png"
        class="first-camera overlay-camera"
        no-spinner
        loading="lazy"
        fit="fill"
        :ratio="2 / 3"
      />
      <transition name="moveDown" appear>
        <div class="overlay-text row">
          <div
            class="
              intro
              col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8
              self-center
            "
          >
            <div class="text-header">
              Fire Prevention: Localizing Fire at Inception with Video Analytics
            </div>
            <br />
            <q-btn
              label="Get Started"
              no-caps
              class="q-mr-sm q-px-lg gradient-background start-btn"
              @click="getStarted"
              :to="{ name: 'Home', hash: '#about' }"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, defineAsyncComponent } from "vue";
import LoadingComponent from "../LoadingComponent";

const FullCarousel = defineAsyncComponent({
  loader: () => import(/* webpackPrefetch: true */ "../FullCarousel.vue"),
  loadingComponent: LoadingComponent,
});

export default {
  components: {
    FullCarousel,
  },
  setup() {
    const gallery = ref([
      "FSD/views/first-view",
      "FSD/views/sample1-view",
      "FSD/views/factory-premises-view",
      "FSD/views/fabric-factory-view",
      "FSD/views/valve-factory-view",
    ]);

    return {
      gallery,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/globalStyles.scss";

.main-wrapper {
  position: relative;
  margin-top: min(-40px, -6.6vw);
}

.first-camera {
  height: 300px;
  width: 200px;
}

.overlay-camera {
  position: absolute;
  top: -10px;
  right: 11vw;
}

/* top-right-bottom-left */
.overlay-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4vw;
  min-height: 350px;
  max-width: 92vw;
}

.intro {
  text-align: center;
  background-color: rgba(249, 232, 187, 0.8);
  color: black;
  padding: 20px;

  .text-header {
    font-size: 4.5em;
    font-weight: bold;
  }
}

.gradient-background {
  background-color: #a40606;
  background-image: linear-gradient(315deg, #a40606 0%, #d98324 74%);
}

.start-btn {
  font-size: 17px;
  margin: 2px;
}

@media (max-width: 1024px) {
  .intro .text-header {
    font-size: 3.5em;
  }
  .overlay-text {
    min-width: 80vw;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 600px) {
  .first-camera {
    height: 200px;
    width: 133px;
  }
  .intro .text-header {
    font-size: 2.5em;
  }
}

@media (min-width: 450px) {
  .start-btn {
    display: none;
  }
}
</style>
