<template>
  <div class="wrapper-home">
    <MainHeader id="mainHeader"/>
    <Applications id="applications" class="section" />
    <AboutUS id="about" class="section" />
    <Partner id="partner" class="section" />
    <Contact id="contact" class="section" />
    <Tech id="products" class="section" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

import MainHeader from "./HomePageComponents/MainHeader";
const AboutUS = defineAsyncComponent({
  loader: () =>
    import(/* webpackPrefetch: true */ "./HomePageComponents/AboutUs.vue"),
});
const Partner = defineAsyncComponent({
  loader: () =>
    import(/* webpackPrefetch: true */ "./HomePageComponents/Partner.vue"),
});
const Applications = defineAsyncComponent({
  loader: () =>
    import(/* webpackPrefetch: true */ "./HomePageComponents/Applications.vue"),
});
const Tech = defineAsyncComponent({
  loader: () =>
    import(/* webpackPrefetch: true */ "./HomePageComponents/Technologies.vue"),
});
const Contact = defineAsyncComponent({
  loader: () =>
    import(/* webpackPrefetch: true */ "./HomePageComponents/Contact.vue"),
});

export default {
  components: {
    MainHeader,
    AboutUS,
    Partner,
    Applications,
    Tech,
    Contact
  },
  setup() {},
};
</script>

<style scoped>
</style>
