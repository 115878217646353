<template>
  <div :style="cssProps" class="body">
    <q-img class="left-picture" src="../assets/camera-left.png" no-spinner />
    <q-img class="right-picture" src="../assets/camera-right.png" no-spinner />
    <section class="section">
      <router-view />
    </section>
    <br />
  </div>
</template>

<script>
import { ref, watch } from "vue";
import techImage from "../assets/Tech/tech-back1.jpg";
import { useRoute } from "vue-router";

export default {
  components: {},
  setup() {
    const route = useRoute();

    const cssProps = ref({
      background: `#d7d5d6`,
    });

    watch(route, () => {
      let path = route.path;
      if (path == "/technologies") {
        cssProps.value.background = `linear-gradient(
        rgba(250, 250, 250, 0.85),
        rgba(250, 250, 250, 0.85)
        ),
        url('${techImage}')`;
      } else {
        cssProps.value.background = `#d7d5d6`;
      }
    });

    return {
      cssProps,
    };
  },
};
</script>

<style scoped>
.body {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  z-index: 10;
  height: auto;
}
.left-picture {
  padding-left: 10px;
  position: sticky;
  top: 58px;
  right: 0;
  width: 10%;
  min-width: 50px;
}
.right-picture {
  padding-right: 10px;
  position: sticky;
  top: 58px;
  left: 100%;
  width: 10%;
  min-width: 50px;
}
.section {
  transition: all 120s ease-in-out;
}
.section:nth-child(even) {
  background-image: linear-gradient(
    rgba(245, 243, 159, 0.2),
    rgba(245, 159, 179, 0.2)
  );
  box-shadow: 0 0 20px 20px rgba(245, 243, 159, 0.2);
  width: 100%;
}

@media (max-width: 450px) {
  .left-picture {
    padding-left: 0;
  }
  .right-picture {
    padding-right: 0;
  }
}

@media (max-width: 1400px) and (min-width: 1024px) {
  .left-picture,
  .right-picture {
    top: 86px;
  }
}

@media (max-width: 1024px) {
  .left-picture,
  .right-picture {
    top: 58px;
  }
}
</style>
